import domReady from '@wordpress/dom-ready';
import Swiper from 'swiper';

domReady(() => {
  const partnersCarousels = document.querySelectorAll(
    '.partners-carousel-block .partners-carousel__carousel'
  );

  if (0 < partnersCarousels.length) {
    partnersCarousels.forEach((carousel) => {
      const args = {
        slidesPerView: 2,
        spaceBetween: 20,
        centerInsufficientSlides: true,
        loop: true,
        autoplay: {
          delay: 2000,
          disableOnInteraction: false,
        },

        breakpoints: {
          600: {
            slidesPerView: 3,
            spaceBetween: 40,
          },
          1080: {
            slidesPerView: 5,
            spaceBetween: 50,
          },
        },
      };

      const partnersSwiper = new Swiper(carousel, args);

      if (document.documentElement.classList.contains('no-animation')) {
        partnersSwiper.autoplay.stop();
      }

      document.addEventListener('disable-animations', function () {
        partnersSwiper.autoplay.stop();
      });

      document.addEventListener('enable-animations', function () {
        partnersSwiper.autoplay.start();
      });
    });
  }
});
